import { ref } from 'vue'
import { getPaddleLink, getPackages } from '@/application/services/message-packages.js'
import { useErrorHandler } from '@/application/composables/responseErrorHandler.js'
import { getLookupPaddleLink } from '@/application/services/lookup-api.js'
import { sendAuthRequestPaddleLink } from '@/application/services/auth-request-packages.js'
import { useModal } from '@/application/composables/modal'
import { getLocalizedServerError } from '@/application/utils/localization'
import { useI18n } from 'vue-i18n'
import { getTimezone } from '@/application/utils/date.js'

export function useMessagePackages(payType) {
  const payHandler = {
    lookup: getLookupPaddleLink,
    authRequest: sendAuthRequestPaddleLink,
  }[payType] || getPaddleLink

  const isDisabled = ref(false)
  const errorMessage = ref('')
  const { responseErrorHandler } = useErrorHandler()
  const { openModal } = useModal()
  const i18n = useI18n()
  const submit = (value) => {
    isDisabled.value = true
    const requestData = { timezone: getTimezone() }
    return payHandler(value.package, requestData)
      .then(({ data }) => {
        isDisabled.value = false
        return data ? data.url : null
      })
      .catch(err => {
        const status = err.response.status
        errorMessage.value = responseErrorHandler(status)
        isDisabled.value = false
        openModal(i18n.t('error'), getLocalizedServerError(i18n, 'errors.general.', err.response))
        return false
      })
  }
  return {
    submit,
    isDisabled,
    errorMessage,
    getPackages,
  }
}
